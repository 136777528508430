<template>
  <div class="change-password-body">
    <p class="h1_2">Yeni Şifre Belirle</p>
    <div class="hiddendiv"></div>
      <label style=" text-align: left;">Yeni Şifre</label>
      <input type="password" placeholder="Yeni Şifre" v-model="password">
    <div class="hiddendiv"></div>
      <label style=" text-align: left;">Yeni Şifre(tekrar)</label>
      <input type="password" placeholder="Yeni Şifre(tekrar)" v-model="passwordValidation">
    <div class="hiddendiv"></div>
    <button :disabled="disableRegister || loading" @click="changePassword()" class="super-button">Değiştir</button>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-auth-changePassword-v1",
    data() {
      return {
        password: null,
        passwordValidation: null,
        loading:false
      }
    },

    methods: {
      changePassword() {
        let code = this.route.query.code;
        let uuid = this.route.query.u;
        this.loading = true;
        this.api.user.changePassword(code, uuid, this.password, this.passwordValidation)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.$router.push('/login');
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.loading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.loading = false;
          })
      }
    },

    computed: {

      disableRegister() {
        return !this.password || !this.passwordValidation
      }
    }
  }
</script>

<style scoped>

  .change-password-body {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }

  input {
    width: 344px;

  }

  label {
    width: 344px;
  }
  @media screen and (max-width: 400px) {
    input {
      width: 90%;
    }
    label {
      width: 90%;
    }
  }

  form {
    text-align: center;
  }

</style>
